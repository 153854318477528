// App.js
import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Polygon } from "@react-google-maps/api";
import "./App.css";

const prevNaplesIncorporatedCoords = [
  { lat: 26.21087636973037, lng: -81.81698913310704 },
  { lat: 26.21162382859373, lng: -81.80094417680316 },
  { lat: 26.182642801643425, lng: -81.79969310655248 },
  { lat: 26.1833908060852, lng: -81.79156642730767 },
  { lat: 26.1605742745122, lng: -81.79135734944963 },
  { lat: 26.160013212660132, lng: -81.78406397229777 },
  { lat: 26.16113574088567, lng: -81.76739405058782 },
  { lat: 26.145236191438528, lng: -81.76635129562025 },
  { lat: 26.144674870935773, lng: -81.78218837941485 },
  { lat: 26.116452006259934, lng: -81.7830679677991 },
  { lat: 26.081034858521377, lng: -81.78150955030647 },
  { lat: 26.07895427081435, lng: -81.795697779467 },
  { lat: 26.091957337212463, lng: -81.80264711619834 },
  { lat: 26.1733236766092, lng: -81.81770401245076 },
];

const naplesIncorporatedCoords = [
  { lat: 26.21165, lng: -81.75142 },
  { lat: 26.13891, lng: -81.750553 },
  { lat: 26.138773, lng: -81.756564 },
  { lat: 26.13841, lng: -81.756562 },
  { lat: 26.138028, lng: -81.766186 },
  { lat: 26.134904, lng: -81.766293 },
  { lat: 26.13508, lng: -81.762333 },
  { lat: 26.130842, lng: -81.762236 },
  { lat: 26.13065, lng: -81.76141 },
  { lat: 26.12753, lng: -81.761206 },
  { lat: 26.126415, lng: -81.762285 },
  { lat: 26.12565, lng: -81.761399 },
  { lat: 26.124126, lng: -81.761188 },
  { lat: 26.124051, lng: -81.760618 },
  { lat: 26.123176, lng: -81.760723 },
  { lat: 26.122439, lng: -81.761539 },
  { lat: 26.122439, lng: -81.761539 },
  { lat: 26.119187, lng: -81.753976 },
  { lat: 26.132774, lng: -81.753545 },
  { lat: 26.132597, lng: -81.759174 },
  { lat: 26.136095, lng: -81.759738 },
  { lat: 26.13607, lng: -81.760411 },
  { lat: 26.13734, lng: -81.760359 },
  { lat: 26.137356, lng: -81.75049 },
  { lat: 26.10915, lng: -81.749916 },
  { lat: 26.108926, lng: -81.766221 },
  { lat: 26.094225, lng: -81.765973 },
  { lat: 26.095233, lng: -81.782357 },
  { lat: 26.079135, lng: -81.782219 },
  { lat: 26.078975, lng: -81.795363 },
  { lat: 26.087033342663418, lng: -81.80038496886324 },
  { lat: 26.091914974594005, lng: -81.80213207834493 },
  { lat: 26.093745533671964, lng: -81.80077321370327 },
  { lat: 26.095401729109298, lng: -81.80193795482504 },
  { lat: 26.106638416329176, lng: -81.80299074968616 },
  { lat: 26.140901983408483, lng: -81.80750615967015 },
  { lat: 26.17470134484016, lng: -81.81492947572481 },
  { lat: 26.210731, lng: -81.816959 },
];

const immokaleeCoords = [
  { lat: 26.515825, lng: -81.563578 },
  { lat: 26.51611, lng: -81.353182 },
  { lat: 26.365727, lng: -81.352037 },
  { lat: 26.395234, lng: -81.386107 },
  { lat: 26.396326, lng: -81.402157 },
  { lat: 26.380154, lng: -81.402173 },
  { lat: 26.381604, lng: -81.419023 },
  { lat: 26.372978, lng: -81.418228 },
  { lat: 26.368667, lng: -81.419836 },
  { lat: 26.366514, lng: -81.424651 },
  { lat: 26.365817, lng: -81.467171 },
  { lat: 26.409661, lng: -81.467152 },
  { lat: 26.407866, lng: -81.56626 },
];

const collierCoords = [
  { lat: 26.330411122535438, lng: -81.84604822903854 },
  { lat: 26.338491594217132, lng: -81.67503371082421 },
  { lat: 26.430869949281742, lng: -81.67965104012195 },
  { lat: 26.4556754155322, lng: -81.60269555182208 },
  { lat: 26.52179719544617, lng: -81.59653911275801 },
  { lat: 26.535565180397114, lng: -81.33489582989651 },
  { lat: 26.324697446547887, lng: -81.31180380604687 },
  { lat: 26.25294140213552, lng: -81.31180380604687 },
  { lat: 26.260975840487916, lng: -80.88170263046902 },
  { lat: 25.728136881589847, lng: -80.89093755950266 },
  { lat: 25.714270788227907, lng: -81.33266206234579 },
  { lat: 25.875017945481417, lng: -81.71436128431492 },
  { lat: 26.112964805336773, lng: -81.84364650465919 },
];

const containerStyle = {
  width: "90vw",
  height: "80vh",
  borderRadius: "20px",
  margin: "0 5vw",
  marginTop: "3vh",
};

function App() {
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [mobile, setMobile] = useState(false);

  const checkMobile = () => {
    setMobile(window.innerWidth < 500);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const checkFluorideStatus = (location) => {
    const naplesPolygon = new window.google.maps.Polygon({
      paths: naplesIncorporatedCoords,
    });
    const immokaleePolygon = new window.google.maps.Polygon({
      paths: immokaleeCoords,
    });

    const collierPolygon = new window.google.maps.Polygon({
      paths: collierCoords,
    });
    const isInCollier = window.google.maps.geometry.poly.containsLocation(
      location,
      collierPolygon
    );
    const isInNaples = window.google.maps.geometry.poly.containsLocation(
      location,
      naplesPolygon
    );
    const isInImmokalee = window.google.maps.geometry.poly.containsLocation(
      location,
      immokaleePolygon
    );
    if (!isInCollier) {
      return "Out of Collier";
    } else {
      if (isInNaples || isInImmokalee) {
        return "Fluoride";
      } else {
        return "No Fluoride";
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (address.trim() === "") {
      setStatus("Please enter an address.");
    } else {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const location = new window.google.maps.LatLng(lat, lng);
        setLocation(location);

        const hasFluoride = checkFluorideStatus(location);
        setStatus(hasFluoride);
        setShowPopup(true);
        //setTimeout(() => setShowPopup(false), 4000);
      }
    }
  };
  return (
    <div className="App">
      <h1 style={{ color: "black" }}>Collier County Added Fluoride Map</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter your address"
            className="address-input"
          />
          <button type="submit" className="check-button">
            Check
          </button>
        </form>
      </div>
      {showPopup && (
        <div
          className={`popup ${
            status === "Fluoride" ? "fluoride" : "no-fluoride"
          }`}
        >
          {status === "Fluoride"
            ? "There is added fluoride in this water. Please consult your water company for precise information."
            : status === "Out of Collier"
            ? "The information provided is for Collier County."
            : "There is no added fluoride in this water. Please consult your water company for precise information."}
        </div>
      )}
      <LoadScript googleMapsApiKey={apiKey} libraries={["geometry"]}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={
            location
              ? location
              : mobile
              ? { lat: 26.182086036640328, lng: -81.63758082780248 }
              : { lat: 26.142036, lng: -81.79481 }
          }
          zoom={location ? 14 : mobile ? 9 : 10}
          options={{
            mapTypeControl: false,
          }}
        >
          {location && <Marker position={location} />}
          <Polygon
            paths={naplesIncorporatedCoords}
            options={{
              fillColor: "#1DA1F4",
              strokeColor: "#1DA1F4",
              strokeWeight: 2,
            }}
          />
          <Polygon
            paths={immokaleeCoords}
            options={{
              fillColor: "#1DA1F4",
              strokeColor: "#1DA1F4",
              strokeWeight: 2,
            }}
          />
          <Polygon
            paths={collierCoords}
            options={{
              fillColor: "rgba(0, 255, 0, 0)",
              strokeColor: "blue",
              strokeWeight: 2,
            }}
          />
          <div className="legend">
            <h3>Map Legend</h3>
            <p>
              <span className="color-box light-blue"></span> Immokalee (Fluoride
              Added)
            </p>
            <p>
              <span className="color-box light-blue"></span> Naples (Fluoride
              Added)
            </p>
            <p>
              <span className="color-box blue"></span> Collier County
            </p>
          </div>
        </GoogleMap>
      </LoadScript>
      <div className="disclaimer">
        <div className="sources">
          <p>Source for the City of Naples Potable water distribution:</p>
          <ul>
            <li>
              <a href="https://www.arcgis.com/apps/webappviewer/index.html?id=92dee2587843453495b2904f630f1fc4">
                ArcGIS:
              </a>{" "}
              This link produces an ArcGIS interactable map capable of providing
              GPS coordinates used to produce the map provided in this
              application. The map from this link was produced by the City of
              Naples through their NaplesGIS project which contains numerous
              publicly available ArcGIS maps.
            </li>
          </ul>
          <p>
            Source for the unincorporated community of Immokalee Potable Water
            Services from the Immokalee Water and Sewer Service:
          </p>
          <ul>
            <li>
              <a href="https://www.colliercountyfl.gov/home/showpublisheddocument/98033/637714957313900000">
                CollierCountyFL.gov:
              </a>{" "}
              This link contains a map that was used as a template to obtain the
              GPS coordinates for the Immakolee Water Distribution. The map was
              part of the Collier County 10-Year Water Supply Facilities Work
              Plan Update from February 2019.
            </li>
          </ul>

          <ul>
            <li>
              <a href="https://www.colliercountyfl.gov/home/showpublisheddocument/98033/">
                CollierCountyFL.gov:
              </a>{" "}
              County website disclosing when Fluoridation of water was stopped
              at 1 p.m. on February 13, 2024:
            </li>
          </ul>
        </div>

        <p>
          Disclaimer: This website is created by Florida citizens and is not an
          official public or government website. The information provided is for
          informational purposes only and should not be considered as legal
          advice or an official statement from any government entity. Exact
          estimations were not able to be obtained and the maps do not account
          for the use of wells in neighborhoods or in other private residences
          found throughout Collier county. Users are advised to verify the
          information independently and consult with appropriate authorities for
          accurate and up-to-date information regarding fluoride availability in
          their area. The creators of this website shall not be held liable for
          any inaccuracies, errors, or omissions in the content provided or any
          actions taken based on the information presented on this site.
        </p>
      </div>
    </div>
  );
}
export default App;
const apiKey = "AIzaSyBzhDcvGQzwtRVTBpDqyOIda5OuDfoNZmc";
